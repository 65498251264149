@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');

tp-missing {
	@apply bg-red-300 rounded-md;
}

#headlessui-portal-root {
	@apply absolute z-[1000];
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
	height: 100%;
	font-family: 'Inter', sans-serif !important;
}

[type='number']:focus,
[type='text']:focus,
[type='tel']:focus,
button:focus,
textarea:focus {
	@apply border-0;
	@apply ring-0;
	@apply ring-transparent;
}

[type='tel'] {
	@apply border-0;
}
